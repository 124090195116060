.searchContents {
  position: relative;
  position: relative;
  max-width: 954px;
  margin: 0 auto;
  width: 100%;
  display: flex;
  gap: 16px;
  align-items: center;
  background: #fffffff2;
  border-radius: 180px;
  padding: 16px;
  padding-left: 0;
  @media (max-width: 767px) {
    background: transparent;
    flex-direction: column;
    gap: 24x;
  }
  &:focus,
  &:hover {
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.628);
    transition: all ease-in-out 100ms;
    @media (max-width: 767px) {
      box-shadow: unset;
    }
  }
  & .searchInput {
    width: 100%;
    border-radius: 45px;
    @media (max-width: 767px) {
      background: #fffffff2;
      padding: 6px 16px;
      border-radius: 45px;
      height: 62px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    & label {
      display: none;
    }

    & input {
      background-color: transparent;
      border: none;
      height: auto;
      border-radius: 45px;
      color: #5894ce;
      font-family: Nunito;
      font-size: 28px;
      font-weight: 500;
      line-height: 38.19px;
      text-align: left;

      padding-left: 99px !important;
      background-image: url("data:image/svg+xml,%3Csvg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M14.6667 25.3333C20.5577 25.3333 25.3333 20.5577 25.3333 14.6667C25.3333 8.77563 20.5577 4 14.6667 4C8.77563 4 4 8.77563 4 14.6667C4 20.5577 8.77563 25.3333 14.6667 25.3333Z' stroke='%23949494' stroke-width='3' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M28 28L22.2 22.2' stroke='%23949494' stroke-width='3' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
      background-position: 48px;
      @media (max-width: 767px) {
        padding-left: 32px !important;
        line-height: 30px;
        font-size: 20px;
        background-position: 5px;
        background-size: 20px;
      }

      &::placeholder {
        font-family: Nunito;
        font-size: 28px;
        font-weight: 500;
        line-height: 38.19px;
        text-align: left;
        color: #949494;
        @media (max-width: 767px) {
          line-height: 30px;
          font-size: 20px;
        }
      }
      &:hover,
      &:focus {
        box-shadow: unset;
      }
    }
  }

  & .searchSelect {
    font-family: Nunito;
    font-size: 28px;
    font-weight: 500;
    line-height: 38.19px;
    text-align: left;
    color: #3d70a1;
    cursor: pointer;
    background: #eef7ff;
    box-shadow: unset;
    border-radius: 45px;
    width: 234px;
    min-width: 234px;
    height: 59px;
    background-position: calc(100% - 20px) 15px;
    border: none;
    background-repeat: no-repeat;
    background-image: url("data:image/svg+xml,%3Csvg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8.39197 11.9344L16.0876 19.63L23.7833 11.9344' stroke='%233D70A1' stroke-width='3' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
    @media (max-width: 767px) {
      height: 44px;
      font-size: 20px;
      line-height: 30px;
      background-position: calc(100% - 20px) 11px;
      background-size: 25px;
      width: auto;
      min-width: calc(100% - 60px);
    }
  }
  & .searchButton {
    cursor: pointer;
    /* position: absolute; */
    top: 0;
    right: 0;

    border-radius: 45px;
    background: transparent;
    height: 66px;
    width: 66px;
    padding: 0;
    min-height: 50px;

    @media (max-width: 767px) {
      height: 44px;
      width: 44px;
      font-size: 13px;
      padding: 0 8px;
    }
    &:hover{
      box-shadow: unset;
    }
    & svg {
      @media (max-width: 767px) {
        height: 44px;
        width: 44px;
      }
    }
  }
  & .selectButton {
    display: flex;
    align-items: center;
    gap: 16px;
    @media (max-width: 767px) {
      background: #fffffff2;
      padding: 6px 16px;
      border-radius: 45px;
      width: 100%;
    }
  }
}
