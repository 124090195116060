.searchTitle {
  font-family: Montserrat;
  font-size: 48px;
  font-weight: 700;
  line-height: 58.51px;
  text-align: center;
  color: #fff;
  margin: 0 0 48px;

  @media (max-width: 767px) {
    font-size: 24px;
    line-height: 34px;
    margin: 0 0 16px;
  }
}

.subtitle {
  color: var(--Black-Shade-Text_Black, #272727);
  font-family: Montserrat;
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  padding: 0;
  margin: 0;

  @media (max-width: 767px) {
    font-size: 20px;
  }
}

.filterWrapper {
  margin: 0 auto;
  padding: 60px 93px;
  min-height: 656px;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url('../../../assets/Banner.png');
  background-position: center;
  @media (max-width: 1500px) {
    gap: 48px;
    padding: 60px 48px;
  }

  @media (max-width: 1023px) {
    flex-direction: column;
    gap: 48px;
    padding: 32px 24px;
    overflow: hidden;
    position: relative;
  }

  & .searchImage {
    width: 38%;

    @media (max-width: 1023px) {
      width: 100%;
      position: absolute;
      z-index: 0;
    }

    & img {
      width: 100%;
      height: 100%;
    }
  }
}

.formWrapper {
  position: relative;
  z-index: 1;
}

.dateSelect {
  position: relative;
  z-index: 10;
}

.radioWrapper {
  display: flex;
  gap: 24px;
  margin: 16px 0;

  padding-left: 8px;

  & label {
    & span {
      color: var(--Black-Shade-Para_Text, #949494);
      font-family: Nunito;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;

      @media (max-width: 767px) {
        font-size: 14px;
      }
    }
  }
}

.multiSelectBox {
  position: relative;
  cursor: pointer;

  & > div {
    border-radius: 10px;
    /* border: 1px solid var(--Web-H-3, #538ABE); */
    background: #f3fcff;
    color: var(--Black-Shade-Text_Black, #272727);
    font-family: Nunito;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    border-color: #b9b9b9;
    padding: 1px 8px;
    position: relative;
    z-index: 10;
    /* &:not(:last-child) {
    min-height: 61px;
    @media (max-width: 767px) {
      min-height: 36px;
    }
  } */
      &:nth-child(4){
        position: absolute;
        z-index: 0;
      }

    & input {
      height: auto;
      color: var(--Black-Shade-Text_Black, #272727);
      font-family: Nunito;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;

      &:focus {
        box-shadow: none;
      }
    }
  }
}

:global(.css-1jqq78o-placeholder) {
  color: var(--Black-Shade-Text_Black, #272727) !important;

  &::placeholder {
    color: var(--Black-Shade-Text_Black, #272727) !important;
  }
}

:global(.css-t3ipsp-control) {
  min-height: 55px !important;

  @media (max-width: 767px) {
    min-height: 36px !important;
  }
}

:global(.css-13cymwt-control) {
  min-height: 55px !important;
  border: none !important;

  @media (max-width: 767px) {
    min-height: 36px !important;
  }
}

:global(.css-1nmdiq5-menu) {
  /* position: relative !important; */
  z-index: 12 !important;
}

:global(.css-tj5bde-Svg) {
  fill: #538abe !important;
}

:global(.css-1jqq78o-placeholder) {
  color: var(--Black-Shade-Para_Text, #949494) !important;
  font-family: Nunito;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  @media (max-width: 767px) {
    font-size: 16px;
  }
}

.doctorSection {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-bottom: 20px;

  & select {
    border: none;
    height: 55px;
    padding-left: 30px;
    position: relative;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='24' fill='%23538ABE' width='24' viewBox='0 0 20 20' aria-hidden='true' focusable='false' class='css-tj5bde-Svg'%3E%3Cpath d='M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z'%3E%3C/path%3E%3C/svg%3E");
    background-size: 20px;
    color: var(--Black-Shade-Para_Text, #949494);
    font-family: Nunito;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    background: #f3fcff;
    @media (max-width: 767px) {
      font-size: 16px;
      height: 36px;
    }

    /* &:hover{
      border: 1px solid #2684ff;
    } */
    &:after {
      position: absolute;
      content: '';
      width: 28px;
      height: 16px;
      right: 35px;
      top: 33px;
    }
  }

  & > div {
    width: calc(50% - 10px);

    @media (max-width: 767px) {
      width: 100%;
    }
  }

  & label {
    /* display: none; */
    color: var(--Black-Shade-Para_Text, #949494);
  }

  & .doctorSelect {
    /* width: calc(50% - 10px);

    @media (max-width: 767px) {
      width: 100%;
    } */
  }
}

.locationInput {
  display: flex;
  position: relative;
  margin: 24px 0 24px;

  & label {
    display: none;
  }

  & button {
    background: var(--Apps-Apps_Secoundary, #538abe);
    height: 55px;
    min-height: 55px;
    width: 200px;
    padding: 0 32px;
    color: var(--Black-Shade-White, #fff);
    font-family: Nunito;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: capitalize;
    position: absolute;
    top: 0;
    right: 0;

    @media (max-width: 767px) {
      width: 120px;
      height: 44px;
      min-height: 44px;
      font-size: 13px;
      padding: 0 8px;
    }
  }

  & .inputContent {
    width: 70%;
    width: 100%;
    border-radius: 45px;
    /* background: var(--Apps-Apps_primary, #3d70a1); */

    & input {
      background-color: #f3fcff;
      border: none;
      height: 55px;
      color: #5894ce;
      font-family: Nunito;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      padding-left: 75px !important;
      background-position: 38px;
      background-image: url("data:image/svg+xml, %3Csvg width='24' height='24' viewBox='0 0 24 24' fill='transparent' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11 19C15.4183 19 19 15.4183 19 11C19 6.58172 15.4183 3 11 3C6.58172 3 3 6.58172 3 11C3 15.4183 6.58172 19 11 19Z' stroke='%233D70A1' stroke-width='2' strokeLinecap='round' stroke-linejoin='round'/%3E%3Cpath d='M21 21.0004L16.65 16.6504' stroke='%233D70A1' stroke-width='2' strokeLinecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");

      @media (max-width: 767px) {
        height: 44px;
        padding-left: 35px !important;
        font-size: 16px;
        background-position: 13px 15px !important;
        background-size: 16px;
      }
    }
  }
}

.dateRangeBox {
  position: relative;
  z-index: 10;
}

.filterContents {
  border-radius: 15px;
  background: #fffffff7;
  box-shadow: 0px 10px 35px 0px rgba(0, 0, 0, 0.03);
  padding: 23px 23px 36px;
  max-width: 826px;
  margin: 24px auto 0;
}

.dateInputs {
  margin-bottom: 22px;

  & label {
    display: none;
  }

  & input {
    border-radius: 15px !important;
    border: none !important;
    padding: 18px 22px 18px 60px !important;
    height: 65px;
    background-position: 30px 20px !important;

    @media (max-width: 767px) {
      height: 48px;
      background-position: 29px 13px !important;
    }
  }
}

.searchContents {
  /* position: relative;

  & .searchInput {
    width: 100%;
    border-radius: 45px;
    background: var(--Apps-Apps_primary, #3d70a1);

    & label {
      display: none;
    }

    & input {
      background-color: transparent;
      border: none;
      height: 73px;
      border-radius: 45px;
      color: #5894ce;
      font-family: Nunito;
      font-size: 24px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      padding-left: 75px !important;
      background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11 19C15.4183 19 19 15.4183 19 11C19 6.58172 15.4183 3 11 3C6.58172 3 3 6.58172 3 11C3 15.4183 6.58172 19 11 19Z' stroke='white' stroke-width='2' strokeLinecap='round' stroke-linejoin='round'/%3E%3Cpath d='M21 21.0004L16.65 16.6504' stroke='white' stroke-width='2' strokeLinecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
      background-position: 38px;

      @media (max-width: 767px) {
        height: 52px;
        padding-left: 35px !important;
        font-size: 16px;
        background-position: 13px 20px !important;
        background-size: 16px;
      }

      &:focus{
        box-shadow: none;
      }
    }
  } */

  /* &:after {
    position: absolute;
    content: '';
    width: 28px;
    height: 16px;
    right: 26px;
    top: 31px;
    background-size: 24px;
    background-image: url("data:image/svg+xml,%3Csvg width='28' height='16' viewBox='0 0 28 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M2 2L14 14L26 2' stroke='white' stroke-width='3' strokeLinecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
    @media (max-width: 767px) {
      width: 28px;
      height: 16px;
      right: -3px;
      top: 22px;
      background-size: 14px;
    }
  } */
  /* & .searchSelect {
    cursor: pointer;
    position: absolute;
    top: 0;
    right: 0;
    & select {
      border-radius: 45px;
      background: var(--Apps-Apps_Secoundary, #538abe);
      height: 73px;
      width: 241px;
      padding: 0 32px;
      color: var(--Black-Shade-White, #fff);
      font-family: Nunito;
      font-size: 22px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      text-transform: capitalize;
      @media (max-width: 767px) {
        height: 52px;
        width: 120px;
        font-size: 13px;
        padding: 0 8px;
      }
    }
  } */
  /* & .searchButton {
    cursor: pointer;
    position: absolute;
    top: 0;
    right: 0;

    border-radius: 45px;
    background: var(--Apps-Apps_Secoundary, #538abe);
    height: 73px;
    width: 241px;
    padding: 0 32px;
    color: var(--Black-Shade-White, #fff);
    font-family: Nunito;
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: capitalize;
    min-height: 50px;

    @media (max-width: 767px) {
      height: 52px;
      width: 120px;
      font-size: 13px;
      padding: 0 8px;
    }

  } */
}

.resetButton {
  color: var(--Black-Shade-Para_Text, #949494);
  font-family: Nunito;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border: none;
  margin-top: 24px;
  cursor: pointer;

  @media (max-width: 767px) {
    font-size: 14px;
  }
}
.searchContents {
  position: relative;
  position: relative;
  max-width: 954px;
  margin: 0 auto;
  width: 100%;
  display: flex;
  gap: 16px;
  align-items: center;
  background: #fffffff2;
  border-radius: 180px;
  padding: 16px;
  padding-left: 0;
  height: 73px;
  @media (max-width: 767px) {
    background: transparent;
    flex-direction: column;
    gap: 24x;
  }
  &:focus,
  &:hover {
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.628);
    transition: all ease-in-out 100ms;
    @media (max-width: 767px) {
      box-shadow: unset;
    }
  }
  & .searchInput {
    width: 100%;
    border-radius: 45px;
    @media (max-width: 767px) {
      background: #fffffff2;
      padding: 6px 16px;
      border-radius: 45px;
      height: 62px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    & label {
      display: none;
    }

    & input {
      background-color: transparent;
      border: none;
      height: auto;
      border-radius: 45px;
      color: #5894ce;
      font-family: Nunito;
      font-size: 28px;
      font-weight: 500;
      line-height: 38.19px;
      text-align: left;

      padding-left: 99px !important;
      background-image: url("data:image/svg+xml,%3Csvg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M14.6667 25.3333C20.5577 25.3333 25.3333 20.5577 25.3333 14.6667C25.3333 8.77563 20.5577 4 14.6667 4C8.77563 4 4 8.77563 4 14.6667C4 20.5577 8.77563 25.3333 14.6667 25.3333Z' stroke='%23949494' stroke-width='3' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M28 28L22.2 22.2' stroke='%23949494' stroke-width='3' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
      background-position: 48px;
      @media (max-width: 767px) {
        padding-left: 32px !important;
        line-height: 30px;
        font-size: 20px;
        background-position: 5px;
        background-size: 20px;
      }

      &::placeholder {
        font-family: Nunito;
        font-size: 28px;
        font-weight: 500;
        line-height: 38.19px;
        text-align: left;
        color: #949494;
        @media (max-width: 767px) {
          line-height: 30px;
          font-size: 20px;
        }
      }
      &:hover,
      &:focus {
        box-shadow: unset;
      }
    }
  }

  & .searchSelect {
    font-family: Nunito;
    font-size: 28px;
    font-weight: 500;
    line-height: 38.19px;
    text-align: left;
    color: #3d70a1;
    cursor: pointer;
    background: #eef7ff;
    box-shadow: unset;
    border-radius: 45px;
    width: 234px;
    min-width: 234px;
    height: 59px;
    background-position: calc(100% - 20px) 15px;
    border: none;
    background-repeat: no-repeat;
    background-image: url("data:image/svg+xml,%3Csvg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8.39197 11.9344L16.0876 19.63L23.7833 11.9344' stroke='%233D70A1' stroke-width='3' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
    @media (max-width: 767px) {
      height: 44px;
      font-size: 20px;
      line-height: 30px;
      background-position: calc(100% - 20px) 11px;
      background-size: 25px;
      width: auto;
      min-width: calc(100% - 60px);
    }
  }
  /* & .searchButton {
    cursor: pointer;
    position: absolute;
    top: 0;
    right: 0;
    border-radius: 45px;
    background: transparent;
    height: 66px;
    width: 66px;
    padding: 0;
    min-height: 50px;
    @media (max-width: 767px) {
      height: 44px;
      width: 44px;
      font-size: 13px;
      padding: 0 8px;
    }
    &:hover{
      box-shadow: unset;
    }
    & svg {
      @media (max-width: 767px) {
        height: 44px;
        width: 44px;
      }
    }
  } */
  & .searchButton {
    background: var(--Apps-Apps_Secoundary, #538abe);
    border-radius: 45px;
    color: var(--Black-Shade-White, #fff);
    cursor: pointer;
    font-family: Nunito;
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    height: 100%;
    line-height: normal;
    min-height: 50px;
    padding: 0 32px;
    position: absolute;
    right: 0;
    text-transform: capitalize;
    top: 0;
    width: 241px;

    @media (max-width: 767px) {
      height: 44px;
      width: 82px;
      font-size: 13px;
      padding: 0 8px;
      top: 16px;
    }
    &:hover {
      box-shadow: unset;
    }
    & svg {
      @media (max-width: 767px) {
        height: 44px;
        width: 44px;
      }
    }
  }
  & .selectButton {
    display: flex;
    align-items: center;
    gap: 16px;
    @media (max-width: 767px) {
      background: #fffffff2;
      padding: 6px 16px;
      border-radius: 45px;
      width: 100%;
    }
  }
}
